<!--  -->
<template>
  <div>
    <div class="notice-body">
      <ul class="notice-list">
        <li>
          <p style="color: rgb(32 3 3) !important; !important">基本信息</p>
        </li>
        <li>
          <p>
            用户名：<span>{{ this.$route.query.username }}</span>
          </p>
        </li>
        <li>
          <p>
            手机号：<span>{{ phone }}</span>
          </p>
        </li>
        <li>
          <button @click="dialogFormVisible = true">修改密码</button>
        </li>
      </ul>
    </div>

    <el-dialog
      title="手机验证"
      :visible.sync="dialogFormVisible"
      style="height:477px width:800px !important;"
    >
      <el-form
        @submit.native.prevent
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="绑定手机号："
          v-model="phone"
          :label-width="formLabelWidth"
        >
          <span>{{ phone }}</span>
        </el-form-item>
        <el-form-item label="输入验证码：" :label-width="formLabelWidth">
          <input class="verification" v-model="ruleForm.code" type="text" />
          <button v-show="show" @click="sendcode()" class="verificationbtn">
            发送验证码
          </button>

          <button v-show="!show" :value="count" class="verificationbtn">
            {{ count }}
          </button>
        </el-form-item>

        <el-form-item
          label="输入新密码："
          prop="pass"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码:"
          prop="checkPass"
          :label-width="formLabelWidth"
        >
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="sendcode" @click="check">完成</button>
      </div>
    </el-dialog>

    <!-- <el-dialog
      @submit.native.prevent
      title="完成"
      :visible.sync="dialogFormVisible3"
      style="height:477px width:800px !important;"
    >
      <div class="stepsbox">
        <ul class="steps">
          <li>开始</li>
          <li>手机验证</li>
          <li>重置密码</li>
          <li class="active">完成</li>
        </ul>
      </div>

      <el-form
        style="
          padding-left: 0px !important;
          text-align: center;
          margin-top: 50px; ">
        <p>恭喜您，重置密码成功！</p>
        <button class="verificationbtn" @click="close">关闭窗口</button>
      </el-form>
    </el-dialog> -->
  </div>
</template>

<script>
import { getCode, changepwd } from "@/api/all.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      timer: null,
      show: true,
      count: "",
      phone: "",
      dialogTableVisible: false,
      dialogFormVisible: false,
      pwd: "",
      newpwd: "",
      formLabelWidth: "120px",
      ruleForm: {
        pass: "",
        checkPass: "",
        code: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    this.phone = localStorage.getItem("phone");
  },
  methods: {
    sendcode() {
      getCode({ phone: this.phone }).then((res) => {
        if (res.code == 200) {
          if (res.code == 200) {
            // 自定义时间 TIME_COUNT 默认 60
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
          // (this.dialogFormVisible = true), (this.dialogTableVisible = true);
          console.log(res);
        }
      });
    },
    check() {
      if (this.ruleForm.pass === this.ruleForm.checkPass) {
        changepwd({
          code: this.ruleForm.code,
          password: this.ruleForm.checkPass,
        }).then((res) => {
          if (res.code == 200) {
            console.log(res);
            console.log("修改成功");
            (this.dialogFormVisible = false), (this.dialogTableVisible = false);
          }
        });
      } else {
        this.$message.error("两次输入密码不一致");
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.el-form {
  padding-left: 50px !important;
}

.el-dialog {
  width: 800px !important;
  height: 477px !important;
}

.stepsbox {
  margin: 0 auto;
  width: 741px;
  height: 75px;
}

.steps {
  position: relative;
  margin-bottom: 30px;
  counter-reset: step;
  /*创建步骤数字计数器*/
  z-index: 5;
}

/*步骤描述*/
.steps li {
  list-style-type: none;
  font-size: 12px;
  text-align: center;
  width: 23%;
  position: relative;
  float: left;
}

/*步骤数字*/
.steps li:before {
  display: block;
  content: counter(step);
  /*设定计数器内容*/
  counter-increment: step;
  /*计数器值递增*/
  width: 32px;
  height: 32px;
  background-color: #b60005;
  line-height: 32px;
  border-radius: 32px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0 auto 8px auto;
}

/*连接线*/
.steps li ~ li:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #b60005;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1;
  /*放置在数字后面*/
}

/*将当前/完成步骤之前的数字及连接线变绿*/
.steps li.active:before,
.steps li.active:after {
  background-color: #b60005;
}

/*将当前/完成步骤之后的数字及连接线变灰*/
.steps li.active ~ li:before,
.steps li.active ~ li:after {
  background-color: #777;
}

.verification {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  width: 258px;
  height: 36px;
  margin-right: 16px;
}

.verification2 {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  width: 350px;
  height: 36px;
  margin-right: 16px;
}

.verificationbtn {
  border: 1px solid #b60005;
  color: #b60005;
  background-color: #ffffff;
  border-radius: 2px;
  width: 107px;
  height: 36px;
}

.sendcode {
  width: 107px;
  height: 36px;
  background-color: #b60005;
  color: #ffffff;
  border-radius: 2px;
}

.notice-list > li > p {
  color: #141414;
  padding-bottom: 22pt;
}

.notice-list > li > p > span {
  color: #141414;
}

.notice-list > li > button {
  background-color: #b60005;
  width: 107px;
  height: 36px;
  border-radius: 2pt;
  color: #ffffff;
}

.page-notice {
  overflow: hidden;
}

.notice-side ul {
  margin: 0 0;
  overflow: hidden;
}

.notice-body {
  height: 811px;
}

.span {
  border: 0px;
}

.notice-list li {
  padding: 0px !important;
  border-bottom: 0px;
  overflow: hidden;
}

p {
  color: #999999 !important;
}
/deep/ .el-input__inner:focus {
  border-color: #b60005;
}
/deep/ .el-input {
  width: 258px;
}
</style>
